import { $ } from '@situation/setdesign.util';

$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            // set to the $tc-theme-name sass variable
            theme: 'lifeofpi',
            'accordion-month-header': { arrowSvgName: 'icon-arrow' },
            'disable-default-analytics': true,
            'selected-event': {
                buttonClass: 'button',
                eventFormat(event) {
                    return `${event.format(
                        'ddd. MMM. DD',
                    )} at <span class="tc-selected-event__time">${event.format(
                        event.smartTimeFormat(''),
                    )}</span><span class="tc-selected-event__meridiem">${event.format('A')}</span>`;
                },
            },
        });
    });
});
