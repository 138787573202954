import {
    $, addAction, doAction, INIT, SCROLL,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const RETURN_TO_TOP_SELECTOR = 'return-to-top';
    const RETURN_TO_TOP_MODIFIER = 'sticky';
    const RETURN_TO_TOP_SHOW = 'returnToTopShow';
    const RETURN_TO_TOP_HIDE = 'returnToTopHide';
    const $returnToTopButton = $(`#${RETURN_TO_TOP_SELECTOR}`);
    if (!$returnToTopButton.length) {
        return;
    }

    $returnToTopButton.on('click', (e) => {
        e.preventDefault();
        $('html,body').scrollTop(0);
    });

    const publishReturnToTop = (shouldShow) => {
        if (shouldShow) {
            doAction(RETURN_TO_TOP_SHOW);
        } else {
            doAction(RETURN_TO_TOP_HIDE);
        }
    };

    addAction(SCROLL, (e, position) => {
        publishReturnToTop(position.top > 1500);
    });

    addAction(RETURN_TO_TOP_SHOW, () => {
        $returnToTopButton.addClass(RETURN_TO_TOP_MODIFIER);
    });

    addAction(RETURN_TO_TOP_HIDE, () => {
        $returnToTopButton.removeClass(RETURN_TO_TOP_MODIFIER);
    });
});
